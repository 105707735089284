import { useState } from 'react';
import { useLayoutEffect } from '../useLayoutEffect';

const useScroll = () => {
  const [state, setState] = useState({
    x: 0, // x와 y의 초기값을 0으로 지정
    y: 0
  });
  const onScroll = () => {
    setState({ x: window.scrollX, y: window.scrollY });
  };
  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return state;
};
export default useScroll;
