import { useIsMobile } from '@/common/contexts/IsMobileContext';

interface MobileViewProps {
  children: React.ReactElement;
}

function MobileView({ children }: MobileViewProps) {
  const isMobile = useIsMobile();
  if (isMobile) {
    return children;
  }
  return null;
}

export default MobileView;
