import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { useMemo } from 'react';

const useDevice = () => {
  const isMobile = useIsMobile();
  const device = useMemo(() => (isMobile ? 'mobile' : 'desktop'), []);

  return { device };
};

export default useDevice;
