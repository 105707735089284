import { useIsMobile } from '@/common/contexts/IsMobileContext';
import type { RouteValue } from '@/common/route';
import route from '@/common/route';
import { NavItem, NavSub, NavSubItem } from '@/components/unit/Navs/type';
import { useTranslation } from 'next-i18next';
import type { ReactElement } from 'react';
import { useCallback, useMemo } from 'react';
import { useDevice } from '../useDevice';
import { useLocale } from '../useLocale';

interface Props {
  iconComponent?: ReactElement;
  iconNeedMainTitles?: string[];
  updates?: boolean[];
}

const notSubPathLists: RouteValue[] = ['/careers', '/insideNhn'];
const noWrapLists = [
  '/esg/informationSecurity',
  '/esg/safetyAndHelath',
  '/esg/socialResponsibility',
  '/esg/corporateGovernance'
];

export default function useRoute({ iconComponent, iconNeedMainTitles, updates }: Props = {}) {
  const isMobile = useIsMobile();
  const { device } = useDevice();
  const { isKo } = useLocale();
  const { t } = useTranslation('common');
  const adaptiveSubNavKey = device as keyof NavSub;

  const mainNavs = useMemo<Omit<NavItem, 'sub'>[]>(
    () => [
      {
        main: {
          title: t('nav.nhn.name'),
          link: route.company.tabs.about
        }
      },
      {
        main: {
          title: t('nav.services.name'),
          link: route.services.tabs.game
        }
      },
      {
        main: {
          title: t('nav.pr.name'),
          link: route.pr.tabs.pressRelease
        }
      },
      {
        main: {
          title: t('nav.ir.name'),
          link: isMobile ? route.ir.tabs.mobileAnnouncementPerformance : route.ir.tabs.disclosure
        }
      },
      {
        main: {
          title: t('nav.esg.name'),
          link: route.esg.tabs.esgManagement
        }
      },
      {
        main: {
          title: t('nav.careers.name'),
          link: route.careers.path
        }
      },
      {
        main: {
          title: t('nav.insideNhn.name'),
          link: route.insideNhn.path
        }
      }
    ],
    [t]
  );
  const subNavs = useMemo<NavSub>(
    () => ({
      desktop: [
        {
          sub: [
            {
              title: t('nav.nhn.about'),
              link: route.company.tabs.about
            },
            {
              title: t('nav.nhn.domesticCorporation').replaceAll(' ', '\n'),
              link: route.company.tabs.domesticCorporation
            },
            {
              title: t('nav.nhn.overseasCorporation').replaceAll(' ', '\n'),
              link: route.company.tabs.overseasCorporation
            },
            {
              title: t('nav.nhn.brand'),
              link: route.company.tabs.brand
            }
          ]
        },
        {
          sub: [
            {
              title: t('nav.services.game'),
              link: route.services.tabs.game
            },
            {
              title: t('nav.services.paymentAd'),
              link: route.services.tabs.paymentAd
            },
            {
              title: t('nav.services.commerce'),
              link: route.services.tabs.commerce
            },
            {
              title: t('nav.services.technology'),
              link: route.services.tabs.technology
            },
            {
              title: t('nav.services.contents'),
              link: route.services.tabs.contents
            }
          ]
        },
        {
          sub: [
            {
              title: t('nav.pr.pressRelease'),
              link: route.pr.tabs.pressRelease
            }
          ]
        },
        {
          sub: [
            {
              title: t('nav.ir.disclosure'),
              link: route.ir.tabs.disclosure
            },
            {
              title: t('nav.ir.stock'),
              link: route.ir.tabs.stock
            },
            {
              title: t('nav.ir.financials'),
              link: route.ir.tabs.financials
            },
            {
              title: t('nav.ir.events'),
              link: route.ir.tabs.events
            },
            {
              title: t('nav.ir.archives'),
              link: route.ir.tabs.archives,
              hidden: !isKo
            }
          ]
        },
        {
          sub: [
            {
              title: t('nav.esg.esgManagement'),
              link: route.esg.tabs.esgManagement
            },
            {
              title: t('nav.esg.environment'),
              link: route.esg.tabs.environment
            },
            {
              title: t('nav.esg.society'),
              link: route.esg.tabs.society
            },
            {
              title: t('nav.esg.governance'),
              link: route.esg.tabs.governance
            },
            {
              title: t('nav.esg.businessConduct'),
              link: route.esg.tabs.businessConduct
            },
            {
              title: t('nav.esg.reportPolicy'),
              link: route.esg.tabs.reportPolicy
            }
          ]
        }
      ],
      mobile: [
        {
          sub: [
            {
              title: t('nav.ir.mobileAnnouncementPerformance'),
              link: route.ir.tabs.mobileAnnouncementPerformance
            },
            {
              title: t('nav.ir.mobileEvents'),
              link: route.ir.tabs.mobileEvents
            },
            {
              title: t('nav.ir.mobileMeetingReservation'),
              link: route.ir.tabs.mobileMeetingReservation
            }
          ]
        }
      ]
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const getSubForMain = useCallback(
    (currentMain: string) => {
      if (!subNavs) return [];

      const targetItems = subNavs[adaptiveSubNavKey][0].sub[0].link.includes(currentMain)
        ? subNavs[adaptiveSubNavKey]
        : subNavs.desktop;
      // 필터 함수
      const filterItems = (items: NavSub['desktop' | 'mobile']) =>
        items.find((item) => item.sub[0].link.includes(currentMain))?.sub || [];

      const filteredItems = filterItems(targetItems).filter((item) => !item.hidden);

      return filteredItems;
    },
    [subNavs]
  );

  const selectedMainFitsSub = useMemo(() => {
    if (!subNavs) return {};

    return subNavs[adaptiveSubNavKey].reduce(
      (acc, item) => {
        acc[item.sub[0].link] = item.sub;
        return acc;
      },
      {} as Record<string, NavSubItem[]>
    );
  }, [subNavs, adaptiveSubNavKey]);

  const updatedMainWithSubNavs = useMemo(() => {
    return mainNavs.map((mainItem) => {
      const subItem = selectedMainFitsSub[mainItem.main.link];
      let modifiedMainItem = {
        ...mainItem,
        ...(subItem && { sub: subItem })
      };
      if (iconComponent && iconNeedMainTitles) {
        if (iconNeedMainTitles?.includes(mainItem.main.title)) {
          modifiedMainItem = {
            ...modifiedMainItem,
            main: {
              ...modifiedMainItem.main,
              iconComponent
            }
          };
        }
      }
      return modifiedMainItem;
    });
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...(updates || []),
    mainNavs,
    selectedMainFitsSub,
    iconComponent,
    iconNeedMainTitles
  ]);

  return {
    mainWithSubNavs: updatedMainWithSubNavs,
    mainNavs,
    subNavs,
    getSubForMain,
    selectedMainFitsSub,
    notSubPathLists,
    noWrapLists
  };
}
