const mixins = Object.freeze({
  displayCenter: 'flex justify-center items-center',
  flexJustifyCenter: 'flex justify-center',
  flexItemsCenter: 'flex items-center'
});
const layout = Object.freeze({
  desktopLayout: 'min-w-1360 w-1360 pl-80 pr-80 mx-auto',
  desktopWidthFullLayout: 'min-w-1360 w-full mx-auto',
  desktopWidth: 'min-w-1360',
  mobileESGTopSpace: 'pt-40v',
  desktopBothSidesWidth: 'px-20',
  desktopIntervalBottom: 'pb-100',
  mobileBothSidesWidth: 'px-20v',
  mobileIntervalBottom: 'pb-100v',
  sideLayoutWithFilterMarginTop: 'mt-42',
  gnbHeight: 72,
  headerHeight: 80,
  deskTopPostHeadHHeight: 84,
  deskTopFreeSpace: 62,
  mobileFreeSpace: 30,
  mobileGnbHeaderHeight: 142,
  iframeHeight: 800,
  mixins
});

export default layout;
