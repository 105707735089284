import locales, { Tlocales } from '@/common/constants/locale';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useLocale = () => {
  const router = useRouter();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language as Tlocales;
  const isKo = i18n.language === locales.korea;
  const isEn = i18n.language === locales.english;
  const isJp = i18n.language === locales.japan;

  // 영문 일 경우 object key prefix EN 삽입
  const languageMatchKey = <T, K extends keyof T>(obj: T, key: K | unknown | any) => {
    if (isEn) {
      const ENKey = `EN${key[0].toLocaleUpperCase()}${key.slice(1)}` as K;
      return obj[ENKey];
    }
    const KOKey = key as K;
    return obj[KOKey];
  };

  // 영문 일 경우 value prefix EN 삽입
  const languageMatchValue = (value: string) => {
    if (isEn) return `EN${value[0].toLocaleUpperCase()}${value.slice(1)}`;
    return value;
  };
  const switchLanguage = (language: string, reload?: boolean) => {
    const changeLanguage = async (lng: string, windowReload?: boolean) => {
      try {
        await i18n.changeLanguage(lng);
        router
          .replace({ pathname: router.pathname, query: router.query }, router.asPath, {
            locale: lng,
            scroll: false
          })
          .then(() => windowReload && window.location.reload());
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('언어 전환 에러', error);
      }
    };

    changeLanguage(language, reload);
  };
  useEffect(() => {
    // 언어 변경 시 페이지 콘텐츠 업데이트
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return {
    isKo,
    isEn,
    isJp,
    switchLanguage,
    currentLanguage,
    languageMatchValue,
    languageMatchKey
  };
};

export default useLocale;
